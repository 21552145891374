import * as React from "react"
import { useState, useRef } from "react"
import { Link } from "gatsby"
import _ from "lodash";
import { Tween, SplitWords } from 'react-gsap'

import Seo from "../components/seo"
import Header from "../components/Header"

import * as styles from '../styles/index.module.css'

const calculatePercent = (value, total) => {
  return Math.ceil((value / total) * 100)
}

function IndexPage() {

  const indexRef = useRef();
  
  const colors = ["#73a5b599", "#b573af99", "#9a73b599", "#73b57999", "#bc7b3099"]
  let [ bgPosition, setBgPosition] = useState({x: 0, y: 50})
  let [ bgColor, setBgColor ] = useState(_.sample(colors))

  const getMousePosition = (e) => {
    let windowWidth = window.screen.width
    let windowHeight = window.screen.height
    
    let width = calculatePercent(e.pageX, windowWidth)
    let height = calculatePercent(e.pageY, windowHeight)

    setBgPosition({ 
      x: width, 
      y: height,
    })
  }
  const changeColor = () => {
    setBgColor(_.sample(colors))
  }

  return (

    <div 
      className="index"
      ref={indexRef}
      onMouseMove={getMousePosition}
      onClick={changeColor}
      style={{
        backgroundImage: `radial-gradient(farthest-side at ${bgPosition.x}% ${bgPosition.y}%, ${bgColor}, #eeeeee )`,
        transition: '.3s ease',
      }}
      >
      <Seo title="Home" />
      <Header />

      <section className={styles.hero}>

        <div className={styles.headerContainer}>
          <Tween from={{y: 70}} rotate={8} duration={1.5} delay={1} ease="power4.out" stagger={0.1}>
            <SplitWords
              wrapper={<div style={{ display: 'inline-block', fontSize: '2em' }}/>}
            >
              Melbourne based Software Developer
            </SplitWords>
          </Tween>
        </div>
          <Tween from={{ autoAlpha: 0 }} duration={.8} delay={2.1} ease="power4.in">
            <Link to="/projects" className={styles.bigButton}>
              View my work →
            </Link> 
          </Tween>
      </section>
    </div>

  
  )
}

export default IndexPage
